import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("components.ConformityTable.Conformity"))+" "),_c(VSpacer),_c(VTextField,{staticClass:"search",attrs:{"append-icon":"search","label":_vm.$t('common.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"sort-by":_vm.settings.ConformityTable.table.sortBy,"sort-desc":_vm.settings.ConformityTable.table.sortDesc,"items-per-page":_vm.settings.ConformityTable.table.itemsPerPage,"item-key":"minion_id","headers":_vm.headers,"items":_vm.conformity,"search":_vm.search,"loading":_vm.loading},on:{"update:sortBy":function($event){return _vm.$set(_vm.settings.ConformityTable.table, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.settings.ConformityTable.table, "sortBy", $event)},_vm.updateSettings],"update:sortDesc":function($event){return _vm.$set(_vm.settings.ConformityTable.table, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.settings.ConformityTable.table, "sortDesc", $event)},_vm.updateSettings],"update:itemsPerPage":function($event){return _vm.$set(_vm.settings.ConformityTable.table, "itemsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.settings.ConformityTable.table, "itemsPerPage", $event)},_vm.updateSettings]},scopedSlots:_vm._u([{key:"item.minion_id",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"text-none",attrs:{"text":"","small":"","to":'/conformity/' + item.minion_id}},[_vm._v(_vm._s(item.minion_id))])]}},{key:"item.last_highstate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_highstate === null ? "" : new Date(item.last_highstate).toLocaleString("en-GB"))+" ")]}},{key:"item.conformity",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.boolRepr(item.conformity),"dark":""}},[_vm._v(_vm._s(_vm.$t(("components.ConformityTable." + (item.conformity))))+" ")])]}},{key:"item.succeeded",fn:function(ref){
var item = ref.item;
return [(item.succeeded != null)?_c(VChip,{staticClass:"ma-2",attrs:{"label":"","outlined":"","color":"green","text-color":"base"}},[_vm._v(" "+_vm._s(item.succeeded)+" ")]):_vm._e()]}},{key:"item.unchanged",fn:function(ref){
var item = ref.item;
return [(item.unchanged != null)?_c(VChip,{staticClass:"ma-2",attrs:{"label":"","outlined":"","color":"orange","text-color":"base"}},[_vm._v(" "+_vm._s(item.unchanged)+" ")]):_vm._e()]}},{key:"item.failed",fn:function(ref){
var item = ref.item;
return [(item.failed != null)?_c(VChip,{staticClass:"ma-2",attrs:{"label":"","outlined":"","color":"red","text-color":"base"}},[_vm._v(" "+_vm._s(item.failed)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"ma-2",attrs:{"small":"","color":"blue","tile":"","dark":"","to":'/conformity/' + item.minion_id}},[_vm._v(" "+_vm._s(_vm.$t("components.ConformityTable.detail"))+" ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"small":"","color":"orange","tile":"","dark":"","to":'/run?tgt=' + item.minion_id + '&fun=state.apply'}},[_vm._v(" "+_vm._s(_vm.$t("components.ConformityTable.highstate"))+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }