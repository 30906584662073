import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[(_vm.jobs.length === 0 && _vm.minions.length === 0)?_c(VCol,{attrs:{"sm":"12"}},[_c(VContainer,{attrs:{"fluid":""}},[_c('p',{staticClass:"display-2 text-center"},[_vm._v(_vm._s(_vm.$t("views.Search.NoResult")))])])],1):_vm._e(),(_vm.jobs.length > 0)?_c(VCol,{attrs:{"sm":"12"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("views.Search.Jobs"))+" "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"search","label":_vm.$t('common.Search'),"single-line":"","hide-details":""},model:{value:(_vm.job_search),callback:function ($$v) {_vm.job_search=$$v},expression:"job_search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"sort-by":"jid","sort-desc":"","headers":_vm.jobs_headers,"items":_vm.jobs,"search":_vm.search,"items-per-page":5},scopedSlots:_vm._u([{key:"item.jid",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"text-none",class:item.jid.includes(_vm.query) ? 'red' : '',attrs:{"text":"","small":"","to":'/jobs/' + item.jid + '/' + item.id}},[_vm._v(_vm._s(item.jid)+" ")])]}},{key:"item.fun",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.fun.includes(_vm.query) ? 'red' : ''},[_vm._v(_vm._s(item.fun))])]}},{key:"item.arguments",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.arguments.length > 20 ? item.arguments.slice(0, 20) + "..." : item.arguments)+" ")]}},{key:"item.success",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.boolRepr(item.success),"dark":""}},[_vm._v(_vm._s(_vm.boolText(item.success)))])]}},{key:"item.alter_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.alter_time).toLocaleString("en-GB"))+" ")]}}],null,false,2247714651)})],1)],1)],1):_vm._e(),(_vm.minions.length > 0)?_c(VCol,{attrs:{"sm":"12"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Minions "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"search","label":_vm.$t('common.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.minions_headers,"items":_vm.minions,"items-per-page":5},scopedSlots:_vm._u([{key:"item.minion_id",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"text-none",class:item.minion_id.includes(_vm.query) ? 'red' : '',attrs:{"text":"","small":"","to":'/minions/' + item.minion_id}},[_vm._v(_vm._s(item.minion_id)+" ")])]}},{key:"item.conformity",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.boolRepr(item.conformity),"dark":""}},[_vm._v(_vm._s(item.conformity == null ? "unknown" : item.conformity)+" ")])]}},{key:"item.last_job",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_job === null ? "" : new Date(item.last_job).toLocaleString("en-GB"))+" ")]}},{key:"item.last_highstate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_highstate === null ? "" : new Date(item.last_highstate).toLocaleString("en-GB"))+" ")]}}],null,false,657946555)})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }