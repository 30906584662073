import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{attrs:{"elevation":_vm.minion == null ? 2 : 0}},[_c(VListItem,{attrs:{"three-line":"","dense":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.$t("components.JobsChartCard.Stats")))])],1),_c(VSpacer),_c(VListItemContent,{staticClass:"mr-6"},[_c(VSelect,{attrs:{"items":_vm.filters,"label":_vm.$t('components.JobsChartCard.Filter')},on:{"change":_vm.updateSettings},model:{value:(_vm.settings.Home.JobsChartCard.filter),callback:function ($$v) {_vm.$set(_vm.settings.Home.JobsChartCard, "filter", $$v)},expression:"settings.Home.JobsChartCard.filter"}})],1),_c(VListItemContent,[_c(VSelect,{attrs:{"items":_vm.periods,"label":_vm.$t('components.JobsChartCard.Period')},on:{"change":_vm.updateSettings},model:{value:(_vm.settings.Home.JobsChartCard.period),callback:function ($$v) {_vm.$set(_vm.settings.Home.JobsChartCard, "period", $$v)},expression:"settings.Home.JobsChartCard.period"}})],1)],1),_c('canvas',{ref:"chart"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }