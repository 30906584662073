import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("components.ScheduleTable.Schedules"))+" "),_c(VSpacer),_c(VTextField,{staticClass:"search",attrs:{"append-icon":"search","label":_vm.$t('common.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"sort-by":_vm.settings.ScheduleTable.table.sortBy,"sort-desc":_vm.settings.ScheduleTable.table.sortDesc,"items-per-page":_vm.settings.ScheduleTable.table.itemsPerPage,"headers":_vm.headers,"items":_vm.schedules,"search":_vm.search,"loading":_vm.loading},on:{"update:sortBy":function($event){return _vm.$set(_vm.settings.ScheduleTable.table, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.settings.ScheduleTable.table, "sortBy", $event)},_vm.updateSettings],"update:sortDesc":function($event){return _vm.$set(_vm.settings.ScheduleTable.table, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.settings.ScheduleTable.table, "sortDesc", $event)},_vm.updateSettings],"update:itemsPerPage":function($event){return _vm.$set(_vm.settings.ScheduleTable.table, "itemsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.settings.ScheduleTable.table, "itemsPerPage", $event)},_vm.updateSettings]},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.boolRepr(item.enabled),"dark":""}},[_vm._v(_vm._s(item.enabled))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"ma-2",attrs:{"small":"","color":item.enabled ? 'orange' : 'green',"tile":"","dark":""},on:{"click":function($event){return _vm.manageSchedule(
                item.enabled ? 'disable_job' : 'enable_job',
                item.name,
                item.minion
              )}}},[_vm._v(" "+_vm._s(item.enabled ? ("" + (_vm.$t("components.ScheduleTable.Disable"))) : ("" + (_vm.$t("components.ScheduleTable.Enable"))))+" ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"small":"","color":"red","tile":"","dark":""},on:{"click":function($event){return _vm.manageSchedule('delete', item.name, item.minion)}}},[_vm._v(" "+_vm._s(_vm.$t("components.ScheduleTable.Delete"))+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }