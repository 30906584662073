import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("components.JobTemplatesTable.JobTemplate"))+" "),_c(VSpacer),_c(VTextField,{staticClass:"search",attrs:{"append-icon":"search","label":_vm.$t('common.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"sort-by":_vm.settings.JobTemplatesTable.table.sortBy,"sort-desc":_vm.settings.JobTemplatesTable.table.sortDesc,"items-per-page":_vm.settings.JobTemplatesTable.table.itemsPerPage,"headers":_vm.headers,"items":_vm.job_templates,"search":_vm.search,"loading":_vm.loading},on:{"update:sortBy":function($event){return _vm.$set(_vm.settings.JobTemplatesTable.table, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.settings.JobTemplatesTable.table, "sortBy", $event)},_vm.updateSettings],"update:sortDesc":function($event){return _vm.$set(_vm.settings.JobTemplatesTable.table, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.settings.JobTemplatesTable.table, "sortDesc", $event)},_vm.updateSettings],"update:itemsPerPage":function($event){return _vm.$set(_vm.settings.JobTemplatesTable.table, "itemsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.settings.JobTemplatesTable.table, "itemsPerPage", $event)},_vm.updateSettings]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"ma-2",attrs:{"small":"","color":"blue-grey","tile":"","dark":"","to":_vm.computeUrl(item, false)}},[_vm._v(" "+_vm._s(_vm.$t("components.JobTemplatesTable.Run"))+" ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"small":"","color":"orange","tile":"","dark":"","to":_vm.computeUrl(item, true)}},[_vm._v(" "+_vm._s(_vm.$t("components.JobTemplatesTable.Edit"))+" ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"small":"","color":"red","tile":"","dark":""},on:{"click":function($event){return _vm.deleteTemplate(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t("components.JobTemplatesTable.Delete"))+" ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }