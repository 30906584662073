import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("components.UserSettings.UserSettings")))]),_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"lg":"2"}},[_c('span',[_vm._v(_vm._s(_vm.$t("components.UserSettings.JobsNotifications")))]),_vm._l((_vm.settings.UserSettings.notifs),function(val,name){return _c('div',{key:name},[_c(VSwitch,{attrs:{"label":_vm.$t(("components.UserSettings." + name)),"color":"primary","hide-details":""},model:{value:(_vm.settings.UserSettings.notifs[name]),callback:function ($$v) {_vm.$set(_vm.settings.UserSettings.notifs, name, $$v)},expression:"settings.UserSettings.notifs[name]"}})],1)})],2),_c(VCol,{attrs:{"lg":"2"}},[_c('span',[_vm._v(_vm._s(_vm.$t("components.UserSettings.MaxNotifications")))]),_c(VTextField,{attrs:{"type":"number"},model:{value:(_vm.settings.UserSettings.max_notifs),callback:function ($$v) {_vm.$set(_vm.settings.UserSettings, "max_notifs", $$v)},expression:"settings.UserSettings.max_notifs"}})],1),_c(VCol,{attrs:{"lg":"2"}},[_c('div',{staticClass:"locale-changer",staticStyle:{"margin-left":"20px"}},[_c('span',[_vm._v(_vm._s(_vm.$t("components.UserSettings.Language")))]),_c('div',[_c(VSelect,{attrs:{"items":_vm.langs},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"25px","height":"25px","margin-right":"5px"},attrs:{"src":item.image}}),_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"25px","height":"25px","margin-right":"5px"},attrs:{"src":item.image}}),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.$i18n.locale),callback:function ($$v) {_vm.$set(_vm.$i18n, "locale", $$v)},expression:"$i18n.locale"}})],1)])])],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.updateSettings}},[_vm._v(_vm._s(_vm.$t("components.UserSettings.Submit")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }