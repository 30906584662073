import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSpeedDial,{attrs:{"fixed":"","bottom":"","right":"","direction":"top","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c(VIcon,[_vm._v("close")]):_c(VIcon,[_vm._v("menu")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_vm._l((_vm.fabs),function(f){return [_c(VTooltip,{key:f.tooltip,attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":f.color},on:{"click":function($event){return _vm.emit('fab_action', f.action)}}},on),[_c(VIcon,[_vm._v(_vm._s(f.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(f.tooltip))])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }