import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VMain,[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","lg":"4"}},[_c(VImg,{staticClass:"elevation-0",staticStyle:{"float":"right"},attrs:{"src":require('../assets/img/logo.png'),"width":"100","aspect-ratio":"1"}}),_c('h2',{staticClass:"text-center font-weight-black display-4 mb-8"},[_vm._v("ALCALI")]),_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"color":"black","dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('views.Login.LoginTitle')))]),_c(VSpacer)],1),_c(VForm,{nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.authenticate.apply(null, arguments)}}},[_c(VCardText,[_c(VTextField,{attrs:{"label":_vm.$t('views.Login.Login'),"name":"login","prepend-icon":"person","type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"label":_vm.$t('views.Login.Password'),"name":"password","prepend-icon":"lock","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.authenticate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('views.Login.SignIn')))])],1)],1)],1)],1),_c(VCol,{attrs:{"sm":"12","align":"center"}},[_c(VBtn,{attrs:{"disabled":!_vm.isInit},on:{"click":_vm.handleClickGetAuth}},[_vm._v(_vm._s(_vm.$t('views.Login.SignIn'))+" "),_c('span',{staticClass:"ml-2"},[_c('GoogleLogo')],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }