import en from "./en.json";
import fr from "./fr.json";
import zh from "./zh.json";
export const defaultLocale = "en";

export const languages = {
  en: en,
  fr: fr,
  zh: zh,
};
