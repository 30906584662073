import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("components.EventsTable.Events"))+" "),_c(VSpacer),_c(VTextField,{staticClass:"search",attrs:{"append-icon":"search","label":_vm.$t('common.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"sort-by":_vm.settings.EventsTable.table.sortBy,"sort-desc":_vm.settings.EventsTable.table.sortDesc,"items-per-page":_vm.settings.EventsTable.table.itemsPerPage,"headers":_vm.headers,"items":_vm.events,"search":_vm.search,"show-expand":"","loading":_vm.loading},on:{"update:sortBy":function($event){return _vm.$set(_vm.settings.EventsTable.table, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.settings.EventsTable.table, "sortBy", $event)},_vm.updateSettings],"update:sortDesc":function($event){return _vm.$set(_vm.settings.EventsTable.table, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.settings.EventsTable.table, "sortDesc", $event)},_vm.updateSettings],"update:itemsPerPage":function($event){return _vm.$set(_vm.settings.EventsTable.table, "itemsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.settings.EventsTable.table, "itemsPerPage", $event)},_vm.updateSettings]},scopedSlots:_vm._u([{key:"item.alter_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.alter_time).toLocaleString("en-GB"))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('pre',[_vm._v(_vm._s(JSON.stringify(_vm.safeParse(item.data), null, 2)))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }