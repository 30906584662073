import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('components.ConformityChart.conformity')))]),_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_vm._l((_vm.conformitynames),function(name){return [_c(VRow,{key:name,attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VCol,{attrs:{"sm":"2"}},[_vm._v(_vm._s(name))]),_c(VCol,{attrs:{"sm":"10"}},[_c(VMenu,{attrs:{"open-on-hover":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('canvas',_vm._g({ref:name,refInFor:true,attrs:{"height":"15"}},on))]}}],null,true)},[_c(VSimpleTable,{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(name))])])]),_c('tbody',{domProps:{"innerHTML":_vm._s(_vm.customTool)}})])],1)],1)],1)]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }