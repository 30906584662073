import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VNavigationDrawer,{attrs:{"mini-variant":_vm.settings.Layout.mini,"app":"","clipped":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VListItem,{staticClass:"elevation-24",on:{"click":function($event){$event.stopPropagation();return _vm.updateDomAndSettings('mini')}}},[_c(VListItemAction,[(_vm.settings.Layout.mini)?_c(VIcon,[_vm._v("arrow_forward")]):_c(VIcon,[_vm._v("arrow_back")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("components.core.Layout.Collapse")))])],1)],1)]},proxy:true}]),model:{value:(_vm.settings.Layout.drawer),callback:function ($$v) {_vm.$set(_vm.settings.Layout, "drawer", $$v)},expression:"settings.Layout.drawer"}},[_c(VList,{staticClass:"py-0",attrs:{"dense":"","nav":"","dark":"","color":"#212121"}},[_c(VListItem,{class:_vm.settings.Layout.mini && 'px-0',attrs:{"two-line":""}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"large":""}},[_vm._v("person")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.username))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.email))])],1)],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.routes),function(route){return _c(VListItem,{key:route.name,attrs:{"to":("" + (route.path))}},[(_vm.settings.Layout.mini)?_c(VListItemAction,[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v(_vm._s(route.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(route.name)))])])],1):_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(route.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(route.name)))])],1)],1)}),1),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"to":"/users"}},[(_vm.settings.Layout.mini)?_c(VListItemAction,[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v("group")])]}}],null,false,3527340433)},[_c('span',[_vm._v(_vm._s(_vm.$t("components.core.Layout.Users")))])])],1):_c(VListItemAction,[_c(VIcon,[_vm._v("group")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("components.core.Layout.Users")))])],1)],1),_c(VListItem,{attrs:{"to":"/settings"}},[(_vm.settings.Layout.mini)?_c(VListItemAction,[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v("settings")])]}}],null,false,1926182315)},[_c('span',[_vm._v(_vm._s(_vm.$t("components.core.Layout.Settings")))])])],1):_c(VListItemAction,[_c(VIcon,[_vm._v("settings")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("components.core.Layout.Settings")))])],1)],1)],1)],1),_c(VAppBar,{attrs:{"color":"black","dark":"","app":"","clipped-left":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.updateDomAndSettings('drawer')}}}),_c(VToolbarTitle,{staticClass:"font-weight-bold"},[_vm._v("ALCALI")]),_c(VSpacer),_c(VExpandTransition,[_c(VTextField,{directives:[{name:"show",rawName:"v-show",value:(_vm.expand_search),expression:"expand_search"}],staticClass:"mx-auto search",attrs:{"flat":"","hide-details":"","label":_vm.$t('components.core.Layout.Search'),"solo-inverted":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchBar.apply(null, arguments)}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c(VBtn,{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){_vm.expand_search = !_vm.expand_search}}},[_c(VIcon,[_vm._v("search")])],1),_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBadge,{attrs:{"color":_vm.notif_nb > 0 ? 'primary' : 'transparent',"overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(_vm.notif_nb > 0)?_c('span',[_vm._v(_vm._s(_vm.notif_nb))]):_vm._e()]},proxy:true}],null,true)},[_c(VIcon,_vm._g({on:{"click":function($event){_vm.notif_nb = 0}}},on),[_vm._v("notifications")])],1)]}}]),model:{value:(_vm.notif_menu),callback:function ($$v) {_vm.notif_menu=$$v},expression:"notif_menu"}},[_c(VCard,{attrs:{"min-width":"500px","max-width":"500px"}},[_c(VList,{attrs:{"max-height":"700px"}},[(_vm.messages.length === 0)?_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.$t("components.core.Layout.NoNotifications")))])],1)],1):_vm._e(),_vm._l((_vm.messages),function(item,i){return _c(VListItem,{key:i,attrs:{"to":item.link}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"dark":"","color":item.color,"size":"62"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.text))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.tag))])],1)],1)})],2),_c(VCardActions,{directives:[{name:"show",rawName:"v-show",value:(_vm.messages.length > 0),expression:"messages.length > 0"}]},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.messages = []}}},[_vm._v(_vm._s(_vm.$t("components.core.Layout.Clear")))])],1)],1)],1),_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","offset-x":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("more_vert")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.updateDomAndSettings('dark')}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("components.core.Layout.ToggleTheme")))])],1),_c(VDivider),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("components.core.Layout.Logout")))])],1)],1)],1)],1),_c(VMain,[_c(VFadeTransition,{attrs:{"mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }