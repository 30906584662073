import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"4","offset":"4"}},[_c(VCard,[_c(VCardTitle,{staticClass:"black white--text subheading",attrs:{"primary-title":""}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('components.NotFound.Title')))])]),_c(VCardText,{staticClass:"display-1 ma-7 text-center"},[_vm._v(" "+_vm._s(_vm.$t('components.NotFound.404'))+" ")]),_c(VCardActions,[_c(VBtn,{staticClass:"primary darken-3 white--text",attrs:{"text":"","to":"/"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("arrow_back")]),_vm._v(" "+_vm._s(_vm.$t('components.NotFound.Back'))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }