import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("components.KeysTable.Keys"))+" "),_c(VSpacer),_c(VTextField,{staticClass:"search",attrs:{"append-icon":"search","label":_vm.$t('common.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"sort-by":_vm.settings.KeysTable.table.sortBy,"sort-desc":_vm.settings.KeysTable.table.sortDesc,"items-per-page":_vm.settings.KeysTable.table.itemsPerPage,"headers":_vm.headers,"items":_vm.keys,"search":_vm.search,"loading":_vm.loading},on:{"update:sortBy":function($event){return _vm.$set(_vm.settings.KeysTable.table, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.settings.KeysTable.table, "sortBy", $event)},_vm.updateSettings],"update:sortDesc":function($event){return _vm.$set(_vm.settings.KeysTable.table, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.settings.KeysTable.table, "sortDesc", $event)},_vm.updateSettings],"update:itemsPerPage":function($event){return _vm.$set(_vm.settings.KeysTable.table, "itemsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.settings.KeysTable.table, "itemsPerPage", $event)},_vm.updateSettings]},scopedSlots:_vm._u([{key:"item.minion_id",fn:function(ref){
var item = ref.item;
return [(item.status === 'accepted')?[_c(VBtn,{staticClass:"text-none",attrs:{"text":"","small":"","to":'/minions/' + item.minion_id}},[_vm._v(_vm._s(item.minion_id))])]:[_vm._v(" "+_vm._s(item.minion_id)+" ")]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.keysRepr(item.status),"dark":""}},[_vm._v(_vm._s(_vm.$t(("components.KeysTable." + (item.status.toString())))))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.keyAction(item.status)),function(action){return [_c(VBtn,{key:action,staticClass:"ma-2",attrs:{"small":"","dark":"","color":_vm.keysRepr(action)},on:{"click":function($event){return _vm.manageKey(action, item.minion_id)}}},[_vm._v(" "+_vm._s(_vm.$t(("components.KeysTable." + action)))+" ")])]})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }